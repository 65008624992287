<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.e-consent") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
            <label class="mb-0">{{ $t("navigations.site code") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              name="account"
              placeholder="Site code"
              v-model="searchData.siteId"
            >
              <option selected value="">{{ $t("navigations.all") }}</option>
              <option
                v-for="site in sites"
                :key="site.siteID"
                :value="site.siteID"
              >
                {{ site.siteCode }}
              </option>
              <!--<option>Archived</option>-->
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">{{ $t("navigations.subject id") }}</label>
            <input
              type="text"
              class="form-control my-md-2 my-lg-0"
              v-model="searchData.subjectid"
            />
          </div>

          <span
            typr="button"
            class="col-sm-12 col-lg-2 save_btn my-1 my-lg-0 mx-lg-1 align-self-end"
            @click="SearchButtonClicked()"
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>{{ $t("navigations.e-consent list") }}</h5>
        <div class="d-flex align-items-center">
          <a
            class="video-call-block"
            data-toggle="modal"
            @click="openPopupVideo()"
          >
            <img
              src="../../assets/img/video_requests.png"
              alt="Video Call Requests"
            />
            <div class="tool-tip">{{ "Video Call Requests" }}</div>
          </a>

          <!-- <button class="video-call-block mr-2" type="button" @click="openPopupVideo()">
      <img src="../../assets/img/video_requests.png" alt="Video call" />

    </button> -->
          <div
            class="position-relative cursor-pointer"
            v-if="patientLists.length != 0"
            @click.prevent="showFilter = !showFilter"
          >
            <img
              src="../../assets/img/filter.png"
              width="17"
              height="17"
              alt="filter table"
            />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="d-flex justify-content-end px-0 mb-2" v-if="showFilter">
          <div class="col-3 px-0" v-on:clickout="dropdownOpen = false">
            <div
              class=" table-multi-select-dropdown"
              @click.stop="dropdownOpen = !dropdownOpen"
            >
              <div class="selected-options">
                <span>{{ $t("navigations.select columns") }}</span>
              </div>
              <div class="dropdown-arrow"></div>
            </div>
            <div v-if="dropdownOpen" class="table-dropdown-content" @click.stop>
              <label v-for="(column, index) in getColumns" :key="index">
                <input
                  type="checkbox"
                  v-model="selectedcolumnobj[column]"
                  :value="true"
                />
                {{ column }}
              </label>
            </div>
          </div>
        </div>
        <div class="text-center" v-if="patientLists.length === 0">
          No data available
        </div>
        <div v-else class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th v-if="selectedcolumnobj['Site Code']">
                  {{ $t("navigations.site code") }}
                </th>
                <th v-if="selectedcolumnobj['Subject ID']">
                  {{ $t("navigations.subject id") }}
                </th>
                <th
                  v-if="selectedcolumnobj['Status']"
                  @click="SortSelected('status', 1)"
                  id="1"
                >
                  <div class="sort_block">
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.status") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
                <th
                  v-if="selectedcolumnobj['Enrolment Date']"
                  class="sort_block"
                  @click="SortSelected('enrollmentDate', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.enrolment date") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  v-if="selectedcolumnobj['Invite Status']"
                  class="sort_block"
                  @click="SortSelected('enrollmentDate', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.invite status") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  v-if="selectedcolumnobj['Consent Status']"
                  class="sort_block"
                  @click="SortSelected('enrollmentDate', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.consent status") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="patient in patientLists" :key="patient.studyId">
                <td v-if="selectedcolumnobj['Site Code']">
                  {{ patient.siteCode }}
                </td>
                <td v-if="selectedcolumnobj['Subject ID']">
                  {{ patient.subjectId }}
                </td>
                <td v-if="selectedcolumnobj['Status']">{{ patient.status }}</td>
                <td v-if="selectedcolumnobj['Enrolment Date']">
                  {{ filter(patient.enrollmentDate) }}
                </td>
                <td
                  v-if="
                    patient.inviteStatus == 'Pending' &&
                      selectedcolumnobj['Invite Status']
                  "
                >
                  <button
                    class="btn-outline btn-default py-0 px-2 border-radius-9"
                    @click.prevent="
                      openPopup(patient.patientId, patient.studyRef)
                    "
                  >
                    {{ patient.inviteStatus }}
                    <i class="fa fa-chevron-right" style="font-size: 8px;"></i>
                  </button>
                </td>
                <td
                  v-if="
                    patient.inviteStatus != 'Pending' &&
                      selectedcolumnobj['Invite Status']
                  "
                >
                  {{ patient.inviteStatus }}
                </td>
                <td v-if="selectedcolumnobj['Consent Status']">
                  {{ patient.consentStatus }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
        <div class="row mt-3 mx-0" v-if="patientLists.length != 0">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="dataTables_paginate paging_simple_numbers pagination float-right"
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPage"
                  :pages="totalPages"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- pagin -->
      </div>
    </div>
  </div>
  <div class="patient__invite__modal__wrapper" v-if="showPopup">
    <div class="patient__invite__modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0 align-self-center">
          {{ $t("navigations.invite subject") }}
        </h4>
        <div
          class="tooltip-wrapper pointer"
          @click.prevent="ClosePopUp()" 
          style="float: right; cursor: pointer;"
        >
          <i class="fa fa-times close-button-popups float-right"></i>
          <span class="custom-tooltip-popups">{{
            $t("navigations.close")
          }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-between p-2">
        <input
          class="form-control my-md-2 my-lg-0"
          type="email"
          @input="OnUserTypeOnEmailField()"
          :placeholder="$t('navigations.email')"
          maxlength="50"
          v-model="inviteEmail"
        /><button
          :disabled="inviteButtonstats"
          class="save_btn my-1 my-lg-0 mx-lg-1 align-self-end"
          @click.prevent="inviteSubject()"
        >
          <i class="fa fa-check px-1 text-white"></i
          >{{ $t("navigations.invite") }}
        </button>
      </div>
      <div class="error-msg px-2 py-1"> <b>{{ err }}</b></div>
    </div>
  </div>

  <!-- video call list -->
  <!-- <div class="patient__login__modal__wrapper" v-if="showPopups == true">
    <div class="video_call__modal__container">
      <div class="modal__title-request d-flex justify-content-between py-2 px-3">
        <h4 class="my-0 align-self-center">Video visit requests</h4>
        <span
          type="button"
          class="align-self-center"
          data-dismiss="modal"
          @click="closemodals()"
          aria-hidden="true"
          ><i class="fa fa-times modal-close align-self-center"></i>
        </span>
      </div>
      <div class="list-group-video">
         <div class="my-0 align-self-center"><div>No data Found</div></div>
      <li   
      
      >
      <div class="row  p-2">
        <div class="col-12 col-md-3"><div></div></div>
        <div class="col-12 col-md-9 d-flex justify-content-between">
        <button
          class="btn-default-accept text-center py-1 px-2 min_w100"
         type="button"
          aria-label="Approve video call"
         
        >
          Approve
        </button>
        <button
          class="btn-default-reject text-center py-1 px-2 min_w100"
          type="button"
          aria-label="Approve video call"
        
        >
          Reject
        </button>
       </div>
      </div>
      <hr class="my-0"/>
      </li>
      
      </div>
    </div>
  </div> -->

  <!--  -->
  <div class="patient__login__modal__wrapper" v-if="showPopups == true">
    <div class="video_call__modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0 align-self-center">Video Call Requests</h4>
        <span
          type="button"
          class="align-self-center"
          data-dismiss="modal"
          @click="closemodals()"
          aria-hidden="true"
          ><i
            class="fa fa-times modal-close align-self-center"
            title="Close"
          ></i>
        </span>
      </div>
      <div class="list-group-video">
        <!-- <div  class="my-0 align-self-center"><div>No data Found</div></div> -->
        <li>
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th class="sort_block">Subjects</th>
                  <th class="sort_block">From Date</th>
                  <th class="sort_block">From Time</th>
                  <th class="sort_block">End Date</th>
                  <th class="sort_block">End Time</th>
                  <th class="sort_block">Status</th>
                  <th class="sort_block text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(request, index) in videoCallRequests"
                  :key="request.id"
                >
                  <td
                    v-for="caller in request.users.filter(
                      (user) => user.isCaller
                    )"
                    :key="caller.uniqueUserId"
                  >
                    {{ caller.email }}
                  </td>
                  <td>
                    {{
                      (() => {
                        const date = new Date(request.scheduledStartTime);
                        const month = String(date.getMonth() + 1).padStart(
                          2,
                          "0"
                        );
                        const day = String(date.getDate()).padStart(2, "0");
                        const year = date.getFullYear();
                        return `${month}-${day}-${year}`;
                      })()
                    }}
                  </td>
                  <td>
                    {{
                      new Date(request.scheduledStartTime).toLocaleTimeString()
                    }}
                  </td>
                  <td>
                    {{
                      (() => {
                        const date = new Date(request.scheduledEndTime);
                        const month = String(date.getMonth() + 1).padStart(
                          2,
                          "0"
                        );
                        const day = String(date.getDate()).padStart(2, "0");
                        const year = date.getFullYear();
                        return `${month}-${day}-${year}`;
                      })()
                    }}
                  </td>
                  <td>
                    {{
                      new Date(request.scheduledEndTime).toLocaleTimeString()
                    }}
                  </td>
                  <td>
                    {{ request.status || "Pending" }}
                  </td>

                  <td
                    class="text-center"
                    v-on:clickout="showSidePopup[index] = false"
                    v-for="caller in request.users.filter(
                      (user) => user.isCaller
                    )"
                    :key="caller.uniqueUserId"
                  >
                    <div
                      v-if="
                        request.status !== 'Accepted' &&
                          request.status !== 'Rejected'
                      "
                      class="position-relative cursor-pointer"
                      @click.prevent="
                        showSidePopup[index] = !showSidePopup[index]
                      "
                    >
                      <i class="fa fa-ellipsis-v"></i>
                    </div>
                    <div class="plan-side-popup" v-if="showSidePopup[index]">
                      <div
                        @click.prevent="
                          openAproveRejectPopup('approve', request)
                        "
                      >
                        Accept
                      </div>
                      <div
                        @click.prevent="
                          openAproveRejectPopup('reject', request)
                        "
                      >
                        Decline
                      </div>
                      <div @click.prevent="rescheduleRequest(request)">
                        Reschedule
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Pagination Controls for Popup -->
          <div class="row mt-3 mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="dataTables_paginate paging_simple_numbers pagination float-right"
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="popupCurrentPage"
                    :pages="popupTotalPages"
                    :range-size="1"
                    active-color="#618bd7"
                    @update:modelValue="loadPopupPage"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Pagination Controls for Popup end -->
        </li>
      </div>
    </div>
  </div>
  <!--  -->

  <!-- Reschedule popup starts -->
  <div v-if="isScheduleModalVisible" class="study_profile_modal__wrapper">
    <div class="study_profile_modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">Reschedule Video Call</h4>
        <div
          class="cursor-pointer"
          :title="$t('close')"
          @click.prevent="closeSchedulemodal()"
        >
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="modal-body py-1">
        <div class="form-group row">
          <div class="col-lg-12 my-0">
            <label class="col-form-label">Notes</label>
            <input
              type="text"
              class="form-control"
              v-model="selectedRequest.title"
              readonly
            />
          </div>

          <!-- Input Field for Email ID -->
          <div class="col-lg-12 my-0">
            <label class="col-form-label">Attendee Email</label>
            <input
              type="email"
              class="form-control"
              :value="
                selectedRequest.users.find((user) => user.isCaller)
                  ? selectedRequest.users.find((user) => user.isCaller).email
                  : ''
              "
              readonly
              placeholder=""
            />
            <!-- <span class="errormsg ml-0" v-if="errors[0]">
                {{ "Require at least one user." }}
              </span> -->
          </div>

          <div class="col-lg-6 my-0">
            <label class="col-form-label"
              >Start Date<sup><i class="fa fa-asterisk imp"></i></sup>
            </label>
            <input
              class="form-control"
              type="date"
              v-model="startDate"
              :min="minDate"
            />
            <!-- <span class="errormsg ml-0" v-if="errors[1]">
                {{ "Select a start date." }}
              </span> -->
          </div>

          <div class="col-lg-6 my-0">
            <label class="col-form-label"
              >End Date<sup><i class="fa fa-asterisk imp"></i></sup
            ></label>
            <input
              class="form-control"
              type="date"
              v-model="endDate"
              :min="minDate"
            />
            <span class="errormsg ml-0" v-if="isEndDateBeforeStartDate">
              End date cannot be earlier than the start date.
            </span>
            <!-- <span class="errormsg ml-0" v-if="errors[2]">
                {{ "Select an end date." }}
              </span> -->
            <!-- <span class="errormsg ml-0" v-if="errors[5]">
                {{ "End date cannot be earlier than the start date." }}
              </span> -->
          </div>

          <div class="col-lg-6 my-0">
            <label class="col-form-label"
              >Start Time<sup><i class="fa fa-asterisk imp"></i></sup
            ></label>
            <input class="form-control" type="time" v-model="startTime" />
            <!-- <span class="errormsg ml-0" v-if="errors[3]">
                {{ "Select a start time." }}
              </span> -->
          </div>

          <div class="col-lg-6 my-0">
            <label class="col-form-label"
              >End Time<sup><i class="fa fa-asterisk imp"></i></sup>
            </label>
            <input class="form-control" type="time" v-model="endTime" />
            <span class="errormsg ml-0" v-if="isEndTimeBeforeStartTime">
              End time cannot be earlier than start time.
            </span>
            <!-- <span class="errormsg ml-0" v-if="errors[4]">
                {{ "Select an end time." }}
              </span> -->
            <!-- <span class="errormsg ml-0" v-if="errors[6]">
                {{ "End time cannot be earlier than start time." }}
              </span> -->
          </div>
        </div>
      </div>
      <div class="modal-footer py-1">
        <div class="justify-content-lg-end">
          <button
            class="save_btn py-1 px-4"
            type="button"
            @click="Reschedule(this.vdoId)"
          >
            Reschedule
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- schedule popup end -->

  <!-- approve/reject popup start -->

  <div class="patient__invite__modal__wrapper" v-if="showAproveRejectPopup">
    <div class="patient__invite__modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0 align-self-center">Add Comments</h4>
        <div
          class="tooltip-wrapper pointer"
          @click.prevent="closeModalAproveReject()"
          style="float: right; cursor: pointer;"
        >
          <i class="fa fa-times close-button-popups float-right"></i>
          <span class="custom-tooltip-popups">{{
            $t("navigations.close")
          }}</span>
        </div>
      </div>
      <div class="modal-body">
        <!-- Reason Input Field -->
        <div class="form-group mb-0">
          <label for="textareatext">Additional comments</label>
          <textarea
            rows="3"
            cols="50"
            type="text"
            v-model="reason"
            class="form-control"
            id="textareatext"
            placeholder=""
            @input="clearReasonError"
          />
          <span v-if="reasonError" class="text-danger">{{ reasonError }}</span>
        </div>
      </div>
      <div class="modal-footer py-1">
        <div class="d-flex justify-content-end">
          <!-- Conditionally Render Approve/Reject Buttons -->
          <button
            v-if="showApproveButton"
            class="save_btn px-3 py-1"
            @click.prevent="handleApprove('Accepted', this.activeRequestId)"
          >
            Accept
          </button>
          <button
            v-if="showRejectButton"
            class="cancel_btn py-1 px-4"
            @click.prevent="handleReject('Rejected', this.activeRequestId)"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- approve/reject popup end -->
</template>
<script src="./eConsent.js"></script>
<style scoped>
.border-radius-9 {
  border-radius: 5px;
  border: 1px solid #618bd7;
}
.modal__title {
  background-color: var(--pop-up-background);
  color: hsl(0, 0%, 100%);
}
.patient__invite__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.patient__invite__modal__container {
  position: fixed;
  width: min(500px, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.color-green {
  color: hsl(120, 100%, 25%);
}

/* style vdoc csll popup */

.btn-default-reject {
  color: red;
  background: white;
  border: 1px solid red;
  border-radius: 4px;
}
.btn-default-accept {
  color: #059f8c;
  background: white;
  border: 1px solid #059f8c;
  border-radius: 4px;
}
.headingunderline {
  text-decoration-line: underline;
}
.patient__login__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.patient__login__modal__container {
  position: fixed;
  width: min(500px, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.modal__title-request {
  background-color: white;
  color: #212529;
  border-bottom: 1px solid #dee2e6;
}
.list-group-video {
  display: flex;
  flex-direction: column;
  padding: 4px 10px;
  margin-bottom: 0;
}
.reject_btn {
  background-color: #ec4758;
  border-color: #ec4758;
  color: white !important;
  transition: background-color 0.5s ease-in-out;
  border-radius: 0.25rem;
}
.reject_btn:hover {
  color: #fff;
  background-color: #ec4758;
  border-color: #ec4758;
}
.reject_btn:active {
  color: #fff;
  background-color: #ec4758;
  border-color: #ec4758;
}
.min_w100 {
  min-width: 100px;
}
.video_call__modal__container {
  position: fixed;
  width: min(50%, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.right-arrow-sm-icon {
  font-size: 0.625rem;
  color: #68696b;
}
li {
  list-style: none;
}
/* schedulepopup */
.study_profile_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}

.study_profile_modal__container {
  position: fixed;
  width: min(50%, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}

.modal__title {
  background-color: #618bd7;
  color: #fff;
}

.save_btn {
  text-align: center;
  background-color: #145faf !important;
  border-color: #145faf !important;
  color: white !important;
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out !important;
}

.save_btn:hover,
.save_btn:focus:focus-visible {
  background-color: #145faf !important;
  border-color: #145faf !important;
}
.video-call-block {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.video-call-block .tool-tip {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.2rem 0.7rem;
  right: 25px;
  top: -30px; /* Position above the icon */
  font-size: 0.7rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.video-call-block:hover .tool-tip {
  visibility: visible;
}
.error-msg {
  color: #ff0000;
}
/* schedulepopup */
</style>
