<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>{{$t('navigations.query view')}}</h2>
        </div>
      <div class="col-lg-2">
  <div class="position-relative cursor-pointer">
    <i class="fa fa-times close my-0" @click="onclosed()"></i>
    <span class="tool-tip">
      {{$t('navigations.close')}}
    </span>
  </div>
</div>

    </div>
  <div class="wrapper wrapper-content py-0" id="printcontent">
    <div class="row mx-lg-0">
      <div class="d-flex flex-row justify-content-end spaceadjustments py-0 px-lg-0">
        <div v-if="queries.formId && roleprivileges.includes('Query Menu View form')" class="position-relative cursor-pointer float-right ml-1" @click.prevent="gotoform()">
          <span class="tool-tip">
            {{$t('navigations.view form')}}
          </span>
          <img src="../../assets/img/view_form.png" alt="View form">
        </div>
        <div v-if="roleprivileges.includes('Query Menu Export pdf')" class="position-relative cursor-pointer float-right ml-3" @click.prevent="exportpdf()">
          <span class="tool-tip">
            {{$t('navigations.export pdf')}}
          </span>
            <img src="../../assets/img/export_pdf.png" alt="Export pdf">
        </div>
      </div>
      <div class="ibox px-lg-0 mb-md-0">
        <div class="ibox-content overflow-hidden p-lg-0">
          <div class="row py-lg-1 header-background">
             <div class="large col-md-5">
                <h4><strong>{{$t('navigations.query id')}}: </strong><span><strong>{{ queries.queryId }}</strong> </span></h4>
            </div>
            <div class="large col-md-5">
              <h4>
                
                <span> <strong>{{queries.query_Subject}}</strong></span>
              </h4>
            </div>
           
            <div class="small text-right col-md-2" v-if="queries.priority">
                  <h5  v-if="queries.priority=='High'">{{$t('navigations.priority')}} : &nbsp; <span class="label label-danger float-right">{{ queries.priority }}</span></h5>
                  <h5  v-else-if="queries.priority=='Medium'">{{$t('navigations.priority')}} : &nbsp; <span class="label label-warning float-right"> {{$t('navigations.medium')}}</span></h5>
                  <h5  v-else-if="queries.priority=='Low'">{{$t('navigations.priority')}} : &nbsp; <span class="label label-success float-right"> {{$t('navigations.low')}}</span></h5>
            </div>
          </div>
        </div>        
        <div class="ibox-content mb-1" v-if="queries.queryFor == 'Page'">          
          <div class="row">
            <div class="col-sm-2 col-md-3 mb-3 mb-lg-0 mt-lg-1">
                                        <!--<h5>Query for:</h5>-->
              {{$t('navigations.site code')}} : <span > <strong>{{ queries.siteId }}</strong></span>
            </div>
            <div class="col-sm-2 col-md-3 mb-3 mb-lg-0 mt-lg-1">
                                    <!--<h5>Query for:</h5>-->
                {{$t('navigations.subject id')}} : <span > <strong>{{ queries.subjectId }}</strong></span>
            </div>
            <div class="col-sm-3 col-md-3 mb-3 mb-lg-0 mt-lg-1">
                                  <!--<h5>Query for:</h5>-->
              {{$t('navigations.visit name')}} : <span> <strong>{{ queries.visitId}}</strong></span>
            </div>
            <div class="col-sm-2 col-md-2 mb-3 mb-lg-0 mt-lg-1">
                                  <!--<h5>Query for:</h5>-->
              {{$t('navigations.page no')}} : <span> <strong>{{ queries.pageNo }}</strong></span>
            </div>
          </div>
        </div>
        <div class="ibox-content mb-1" v-else-if="queries.queryFor == 'Field'">          
          <div class="row">
            <div class="col-sm-3 col-md-3 mb-3 mb-lg-0 mt-lg-1">
                                        <!--<h5>Query for:</h5>-->
              {{$t('navigations.site code')}} : <span > <strong>{{ queries.siteId }}</strong></span>
            </div>
            <div class="col-sm-3 col-md-3 mb-3 mb-lg-0 mt-lg-1">
                                    <!--<h5>Query for:</h5>-->
                 {{$t('navigations.subject id')}} : <span > <strong>{{ queries.subjectId }}</strong></span>
            </div>
             <div class="col-sm-3 col-md-2 mb-3 mb-lg-0 mt-lg-1">
                                  <!--<h5>Query for:</h5>-->
              {{$t('navigations.visit name')}} : <span> <strong>{{ queries.visitId }}</strong></span>
            </div>
            <div class="col-sm-3 col-md-2 mb-3 mb-lg-0 mt-lg-1">
                                  <!--<h5>Query for:</h5>-->
              {{$t('navigations.page no')}} : <span> <strong>{{ queries.pageNo }}</strong></span>
            </div>
            <div class="col-sm-3 col-md-2 mb-3 mb-lg-0 mt-lg-1">
                                  <!--<h5>Query for:</h5>-->
              {{$t('navigations.field name')}} : <span> <strong>{{ queries.fieldName }}</strong></span>
            </div>
          </div>
        </div>
        <div class="ibox-content mb-1"  v-else-if="queries.queryFor == 'Form'">          
          <div class="row">
            <div class="col-sm-3 col-md-3 mb-3 mb-lg-0 mt-lg-1">
                                        <!--<h5>Query for:</h5>-->
              {{$t('navigations.site code')}} : <span > <strong>{{ queries.siteId }}</strong></span>
            </div>
            <div class="col-sm-3 col-md-3 mb-3 mb-lg-0 mt-lg-1">
                                    <!--<h5>Query for:</h5>-->
                {{$t('navigations.subject id')}} : <span > <strong>{{ queries.subjectId }}</strong></span>
            </div>
            <div class="col-sm-3 col-md-3 mb-3 mb-lg-0 mt-lg-1">
                                  <!--<h5>Query for:</h5>-->
             {{$t('navigations.visit name')}} : <span> <strong>{{ queries.visitId }}</strong></span>
            </div>
          </div>
        </div>
        <div class="ibox-content mb-1 d-none" v-else>         
        </div>
        <div class="ibox-content mb-1 mb-md-0" v-for="(message,index) in lastMessage" :key="index" >
          <div class="row">
            <div class="medium mb-0">
								<!--<span class="font-normal">From: <strong>Michael Novek</strong></span>-->
                                <!--<p>From:<strong>veenav@datamatica.uk </strong> <span class="text-query"><i class="fa fa-clock-o"></i> <small>17/11/2021, 06.00pm</small></span></p>-->
							<h5 class="small-text">{{$t('navigations.from')}} : <strong>{{message.raisedBy}}</strong> <span><i class="fa fa-clock-o"></i> <small>{{ filter(message.raisedDate.slice(0, 10)) }}, {{ formatTime(message.raisedDate) }}</small></span></h5>
            </div>
            <div class="media-body mb-2" v-if="message.messagesRecipients[0].recipients.length <= 1">	
              <medium class="text-muted">{{$t('navigations.to')}} : <span>{{ message.messagesRecipients[0].recipients[0] }}</span></medium>
            </div>
            <div class="media-body mb-2" v-else>	 {{$t('navigations.to')}} :
              <medium class="text-muted"   v-for="(recipient, index) in message.messagesRecipients[0].recipients" :key="index">
                <span v-if="index == message.messagesRecipients[0].recipients.length - 1">
                  {{ recipient }}&nbsp;</span>
                  <span v-else>
                  {{ recipient }},&nbsp;</span>
              </medium>
            </div>
            <p>
              {{ message.queryMessage }}
            </p>
          </div>
          <div  class="form-group row" >
            <label class="col-sm-12 col-md-1 col-form-label">{{$t('navigations.recipients')}} </label>
         
            <div class="col-sm-12 col-md-11">                                   
              <Multiselect 
               :disabled ="queries.queryOpenStatus =='Closed'"  
                v-model="values.value"
                mode="tags"
                valueProp="email"
                :placeholder="$t('navigations.select recipients')"
                label="name"
                :options="values.options"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-theme" 
              >
                <template v-slot:option="{ option }">
                  {{ option.userName }}
                </template>
                <template
                  v-slot:tag="{ option, handleTagRemove, disabled }"
                >
                  <div class="multiselect-tag is-user">
                    {{ option.userName }}
                    <span
                      v-if="!disabled"
                      class="multiselect-tag-remove"
                      @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>
          <div class="form-group row px-lg-0 mx-lg-0 mb-lg-0" :class="{ removeButtons: removeButtons }">
              <div class="col-lg-12 my-2 my-lg-0 px-lg-0">
                <textarea rows="5" :placeholder="$t('navigations.reply')" v-model="postReply"></textarea>
              </div>
                <div id="childnodetoremove"
                class="col-lg-12 my-1 my-lg-0 p-0 d-flex flex-row justify-content-sm-end justify-content-lg-end " :class="{ removeButtons: removeButtons }"
              >
                  <div v-if="roleprivileges.includes('Query Menu Reply')" class="disabled-tooltip">
                    <span class="pointer">
                        {{$t('navigations.reply')}}
                    </span>
                    <button
                        class="btn btn-xs"
                          :disabled="isDisabled"
                          @click.prevent="onReply()"
                        >
                      <img src="../../assets/img/reply.png" alt="">
                    
                    </button>
                   
                  </div>
                  <div v-if="roleprivileges.includes('Query Menu Close')" class="disabled-tooltip">
                    <span class="pointer">
                        {{$t('navigations.close')}}
                    </span>
                    <button
                      class="btn btn-xs"
                      @click.prevent="statusChange(removeButtons, showForm)"
                    >
                      <img src="../../assets/img/close.png" alt="">
                    </button>
                  </div>
                  <div v-if="roleprivileges.includes('Query Menu Reply and Close')" class="disabled-tooltip">
                    <span class="pointer">
                        {{$t('navigations.reply and close')}}
                    </span>
                    <button
                      class="btn btn-xs pe-lg-0"
                      :disabled="isDisabled"
                      @click.prevent="replyClose(removeButtons, showForm)"
                    >
                      <img src="../../assets/img/reply_close.png" alt="">
                    </button>
                  </div>
              </div>
            </div>
          </div>
          <div class="my-1 mb-0 mt-1 displayNone" :class="{ showForm: showForm }">
            <span
              ><i class="fa fa-warning warning"></i> {{$t('navigations.this query is currently closed')}}
            </span>
          </div>
      </div>
      <div
        class="form-group row px-lg-0 mx-lg-0 my-lg-0"
        :class="{ removeButtons: removeButtons }"
      >
        
      </div>
    </div>
    <div class="ibox-content mt-1 mb-lg-3" v-if="length > 1">
       <h2>{{$t('navigations.previous replies')}}</h2>
      <div  v-for="(message,index) in messages.slice().reverse()" :key="index" >
        <div v-if="index!=0">
          <div class="social-feed-box" >
            <div class="social-avatar">
              <div class="media-body ">
                  <h5>
                  <span class="float-right font-normal"><strong>{{ filter(message.raisedDate.slice(0, 10)) }}, {{ formatTime(message.raisedDate)}}</strong></span>
                  <span class="small-text">{{$t('navigations.from')}}: <strong> {{ message.raisedBy }}</strong></span> 
                  </h5>
              </div>	
              <div class="media-body">	
                <medium class="text-muted">{{$t('navigations.to')}} : <span v-if="message.messagesRecipients[0].recipients.length <= 1">{{ message.messagesRecipients[0].recipients[0] }}</span>
                <span v-else>  <span
                  v-for="(recipient, index) in message.messagesRecipients[0]
                    .recipients"
                  :key="index"
                >
                  <span
                    v-if="
                      index == message.messagesRecipients[0].recipients.length - 1
                    "
                  >
                    {{ recipient }}&nbsp;
                  </span>
                  <span v-else> {{ recipient }}, </span>
                </span></span>
                </medium>
              </div>
              <div class="social-body ps-lg-0">
                <p>
                {{ message.queryMessage }}
                </p>
              </div>
            </div>
          </div>
        </div>        
      </div>       
    </div>
  </div>
  <!-- export pdf starts -->

<!-- export pdf ends -->
</template>
<script src="./queryMap.js"></script>
<style scoped>
.disabled-tooltip{
  position: relative;
}
.disabled-tooltip .pointer{
  visibility:hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius:0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top:-35px;
  font-size:0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
   -webkit-transition:visibility 75ms ease-in;
  transition:visibility  75ms  ease-in;
}
.disabled-tooltip:hover .pointer{
  visibility:visible;
   -webkit-transition:visibility 30ms ease-in;
  transition:visibility 30ms  ease-in;
}
.small-text{
  font-size:0.8rem !important;
  }
.save_btn {
  height: auto !important;
}
.multiselect-theme {
  --ms-tag-bg: rgb(210, 210, 210);
  --ms-tag-color: rgb(60, 60, 60);
  --ms-option-bg-selected-pointed: transparent;
  --ms-option-bg-selected: transparent;
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.warning {
  color: red;
  font-size: 20px;
}
.displayNone {
  display: none;
}
.showForm {
  display: block;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #eeeeee;
  -o-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  background: #ffffff;
}
.removeButtons {
  display: none;
}
.ibox-content {
  border-width: 0px;
}
textarea {
  resize: auto;
  border-color: #bb458f !important;
  width: 100%;
}
textarea:focus-visible,
textarea:focus-within {
  border-color: #bb458f !important;
}

.d-flex {
  justify-content: space-around;
  padding: 15px 20px;
}
.date {
  width: auto;
}
.content {
  width: 90%;
}
.spacebtwn{
  margin-left: 10px !important;
}
.spaceadjustments {
    margin-top: 15px;
    margin-bottom: 5px;
}
.header-background{
padding-left:20px;
padding-right:20px;
background-color:#b2c5f4!important
}
.overflow-hidden{
  overflow:hidden;
}

@media (max-width: 768px) {
  .d-flex {
    flex-direction: column;
  }
  .date {
    text-align: left;
    position: relative;
    padding-top: 30px;
  }
  .content {
    border-left: 0px;
  }
  .timeline-item .date i {
    left: 10px;
    border-right: 1px solid #e7eaec;
  }
}
.page-heading{
  display: flex !important;
  justify-content: space-between;
}
.main-pdf-container{
  width: 100%;
  z-index: -2;
  position: absolute;
  overflow: hidden;
  visibility: hidden;
}
.position-relative {
  position: relative;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

</style>
