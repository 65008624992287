import store from "../../store/index";
import axios from "axios";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import jwt_decode from "jwt-decode";
export default {
  name: "eConsent",
  components: {
    VPagination,
  },
  data() {
    return {
      auditpopup: false,
      showPopups: false,
      minDate: new Date().toISOString().split("T")[0],
      totalCount: 0,
      uniqUserId:"",
      isScheduleModalVisible: false,
      showSidePopup: [false],
      showAproveRejectPopup: false,
      audithistortpopup: false,
      changeHeight: false,
      inviteButtonstats: true,
      showFilter: false,
      dropdownOpen: false,
      selectedcolumnobj: {},
      defaultdate: "",
      xlsFile: "",
      roleprivileges: "",
      ItemID: "",
      AuditId: "",
      auditHistoryLists: [],
      currentPage: 1,
      sites: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patient/`,
      baseapi: process.env.VUE_APP_Service_URL,
      auditLists: [],
      patientLists: [],
      patientIds: [],
      countries: [],
      enablePrevious: true,
      enableNext: true,
      showPopup: false,
      totalPages: 1,
      popupCurrentPage: 1,
      popupItemsPerPage: 5,
      popupTotalPages: 1,
      siteId: "",
      err: "",
      subjectid: "",
      name: "",
      status: "",
      pageIndex: 1,
      pageSize: 5,
      sortProperty: "enrollmentDate",
      inviteEmail: "",
      sortorder: 1,

      searchData: {
        siteId: "",
        subjectid: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        sortProperty: "createdAt",
        sortorder: 0,
      },
      sheduleList: [],
      isVisitCreated: 0,
      siteCode: "",
      videoCallRequests:[],
      selectedRequest: null,
      approvedStatus:null,
      endTime : "",
      startTime : "",
      endDate: "",
      startDate: "",
      reasonError: '',
      reason:'',
      sortpropertyy:"createdAt"
    };
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      this.search();
      this.getsites();
      this.initializeColumns();
    });
    await this.setroleprivileges();
    await this.search();
    await this.getsites();
    this.initializeColumns();
  },
  computed: {
    formattedStartDate() {
      return this.selectedRequest ? new Date(this.selectedRequest.scheduledStartTime).toISOString().substr(0, 10) : '';
    },
    formattedEndDate() {
      return this.selectedRequest ? new Date(this.selectedRequest.scheduledEndTime).toISOString().substr(0, 10) : '';
    },
    formattedStartTime() {
      return this.selectedRequest ? new Date(this.selectedRequest.scheduledStartTime).toTimeString().substr(0, 5) : '';
    },
    formattedEndTime() {
      return this.selectedRequest ? new Date(this.selectedRequest.scheduledEndTime).toTimeString().substr(0, 5) : '';
    },
    isEndDateBeforeStartDate() {
      return this.startDate && this.endDate && new Date(this.startDate) > new Date(this.endDate);
    },
    isEndTimeBeforeStartTime() {
     
      if (this.startDate === this.endDate && this.startTime && this.endTime) {
        const startTime = new Date(`1970-01-01T${this.startTime}:00`);
        const endTime = new Date(`1970-01-01T${this.endTime}:00`);
        const isBefore = startTime >= endTime;
  
        // Log for debugging
        console.log("isEndTimeBeforeStartTime:", isBefore);
        return isBefore;
      }
      return false;
    },
  callerEmail() {
        const caller = this.request.users.find(user => user.isCaller === true);
        return caller ? caller.email : ''; 
      },
      isRequestForEConsent() {
        return this.videoCallRequests.RequestFor === "E-consent support";
      },
  },
  methods: {
    toDate(dateTime){
      return new Date(dateTime).toISOString().substr(0, 10);
    },
    initializeColumns() {
      if (this.patientLists.length > 0) {
        const allowedKeys = [
          "siteCode",
          "subjectId",
          "status",
          "enrollmentDate",
          "inviteStatus",
          "consentStatus",
        ];
        this.getColumns = Object.keys(this.patientLists[0]).filter((column) =>
          allowedKeys.includes(column)
        );
        const fieldLabels = {
          siteCode: "Site Code",
          subjectId: "Subject ID",
          status: "Status",
          enrollmentDate: "Enrolment Date",
          inviteStatus: "Invite Status",
          consentStatus: "Consent Status",
        };

        const readableData = allowedKeys.map((key) => fieldLabels[key] || key);

        this.getColumns = readableData;
        this.selectedColumns = [...this.getColumns];
        this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});
      }
    },
    SortSelected(sortProp, id) {
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        if (this.sortorder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.searchData.pageIndex = 1;
      this.currentPage = 1;
      this.sortProperty = sortProp;
      this.sortorder = this.sortorder == 0 ? 1 : 0;
      this.search();
    },
    async getsites() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sites = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    OnUserTypeOnEmailField() {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (emailPattern.test(this.inviteEmail)) {
        this.inviteButtonstats = false;
        this.err = "";
      } else {
        this.inviteButtonstats = true;
        this.err = "Please enter a valid email";
      }
    },
    async editPatient(id) {
      this.$router.push(`/managePatient/${id}`);
    },
    openPopup(id, name) {
      this.inviteEmail = "";
      this.showPopup = true;
      this.inviteButtonstats = true;
      this.subjectid = id;
      this.name = name;
    },
    ClosePopUp(){
      this.showPopup = false;
      this.err = "";
    },
  
    async inviteSubject() {
      const idtoken = store.getters.getIdToken;
      await axios
        .post(
          `${this.baseapi}/account-core/user/invitesubject`,
          {
            emailID: this.inviteEmail,
            studyName: this.name,
            subjectID: this.subjectid,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          alert(this.$t("navigations.e-consent sent successfully"));
          this.search();
          this.showPopup = false;
          this.inviteEmail = "";
          this.err = "";
        })
        .catch((err) => {
          this.err = "";
          if (err.response.data) {
            if (
              err.response.data.errors.EmailID[0] ==
              "The EmailID field is required."
            ) {
              this.err = this.err.response.data.errors.EmailID[0];
            } else if (
              err.response.data.errors.StudyName[0] ==
              "The StudyName field is required."
            ) {
              this.err = this.err.response.data.errors.StudyName[0];
            } else if (
              err.response.data.errors.SubjectID[0] ==
              "The SubjectID field is required."
            ) {
              this.err = this.err.response.data.errors.SubjectID[0];
            }
          }
        });
    },
    async search() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      // if (this.searchData.siteId == "All") {
      //   this.searchData.siteId = "";
      // }
      this.siteId = this.searchData.siteId;
      this.subjectid = this.searchData.subjectid;
      this.status = this.searchData.status;
      this.pageIndex = this.searchData.pageIndex;
      this.pageSize = this.searchData.pageSize;
      await axios
        .get(
          `${this.baseUrl}listpatient?SiteId=${this.siteId}&Studyid=${currentstudy}&SubjectId=${this.subjectid}&Status=${this.searchData.status}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=${this.sortProperty}&SortOrder=${this.sortorder}&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patientLists = res.data.results;
          this.siteCode = this.patientLists.subjectId;
          this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalPages = this.totalPages + 1;
          }
        });
    },
    async loadPage(page) {
      this.currentPage = page;
      this.searchData.pageIndex = page;
      this.search();
    },
    async SearchButtonClicked() {
      this.currentPage = 1;
      this.searchData.pageIndex = 1;
      this.search();
    },

    async openPopupVideo() {
      
      this.showPopups = true;
      await this.VideoCallRequestList(this.popupCurrentPage);
    },
     async VideoCallRequestList(page) {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      this.pageIndex = page;

      axios.post(`${this.baseapi}/management/videocall/listscheduledcalls?studyId=${currentstudy}`,
                {
                  "sortProperty": "CreatedAt",
                  "isDescending": false,
                  "pageSize": 10,
                  "pageNumber": this.pageIndex
                },
                {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                  },
                }
      )
      .then(response => {
        console.log("scheduledcalllist",response.data);
        this.videoCallRequests = response.data.entities; 
        const totalRecords = response.data.totalCount;
        this.popupTotalPages = Math.ceil(totalRecords / this.pageSize);
      })
      .catch(error => {
        console.error("Error fetching video call requests:", error);
      });




      const decodedToken = jwt_decode(store.getters.getIdToken);
      const email = decodedToken.email; 
      console.log(email);

     },
     async loadPopupPage(page) {
      console.log("pagessss",page)
      this.popupCurrentPage = page;
      this.VideoCallRequestList(page);
    },
    async rescheduleRequest(request) {
      this.selectedRequest = request;
      this.vdoId = request.id;
      this.startDate = this.convertUTCtoDate(this.selectedRequest.scheduledStartTime);
      this.endDate =  this.convertUTCtoDate(this.selectedRequest.scheduledEndTime);
      this.startTime = this.convertUtcToIst(this.selectedRequest.scheduledStartTime); 
      this.endTime =  this.convertUtcToIst(this.selectedRequest.scheduledEndTime);
      
      this.isScheduleModalVisible = true;
    },
    async closemodals() {
      this.showPopups = false;
    },
    async closeSchedulemodal() {
      this.isScheduleModalVisible = false;
    },
    async openAproveRejectPopup(approvalCondition,requestContent) {
      this.showAproveRejectPopup = true;
      this.activeRequestId = requestContent.UniqueUserId;
      this.requestId = requestContent.id;
     
      if (approvalCondition === "approve") {
        this.showApproveButton = true;
        this.showRejectButton = false;
      } else if (approvalCondition === "reject") {
        this.showApproveButton = false;
        this.showRejectButton = true;
      } else {
        this.showApproveButton = false;
        this.showRejectButton = false;
      }
    },
    handleApprove(condition,uniqUserId) {
      const idtoken = store.getters.getIdToken;
      this.reasonError = '';
      console.log("Approval ",this.requestId);
      axios.put(`${this.baseapi}/management/videocall/respondsubjectcall?callID=${this.requestId}&action=${condition}&notes=${this.reason}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then(response => {
        // console.log("Approval successful", response.data);
        alert("Video call request accepted successfully");
        this.VideoCallRequestList(this.popupCurrentPage)

        this.showAproveRejectPopup = false;
      })
      .catch(error => {
        console.error("Error approving request", error);
      });
    },
    handleReject(condition,uniqUserId) {
      const idtoken = store.getters.getIdToken;
      if (!this.reason) {
        this.reasonError = "Please provide a reason for declining.";
        return;
      }
      this.reasonError = '';
      axios.put(`${this.baseapi}/management/videocall/respondsubjectcall?callID=${this.requestId}&action=${condition}&notes=${this.reason}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then(response => {
        // console.log("Rejection successful", response.data);
        alert("Video call request declined successfully");
        this.VideoCallRequestList(this.popupCurrentPage)

        this.showAproveRejectPopup = false;
      })
      .catch(error => {
        console.error("Error approving request", error);
      });
    },
    Reschedule(reqestContent) {
      if (!this.isEndDateBeforeStartDate && !this.isEndTimeBeforeStartTime) {
      const idtoken = store.getters.getIdToken;
      const caller = this.selectedRequest.users.find(user => user.isCaller);
      const rescheduleData = {
        callID: this.vdoId,
        startTime: new Date(this.startDate + ' ' + this.startTime).toISOString(),
        endTime: new Date(this.endDate + ' ' + this.endTime).toISOString(),
      };
      axios.post(`${this.baseapi}/management/videocall/reschedulecall`, rescheduleData,
        
        { headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      }
      )
      
        .then(response => {
          console.log("Reschedule successful", response.data);
          alert("Video call rescheduled successfully");
          this.VideoCallRequestList(this.popupCurrentPage); 
          this.closeSchedulemodal(); 
        })
        .catch(err => {
          console.log(err.response.data.errors.DomainValidations[0]);
          if (err.response && err.response.data && err.response.data.errors.DomainValidations[0] === "Invalid start time") {
            alert("The selected time is no longer available. Please choose an upcoming time for the video call.");
          } else {
            console.log(err);
            alert("An error occurred while scheduling the video call.");
          }
          
         
        });
      }
    },
    convertUTCtoDate(utcDatetime) {
      const date = new Date(utcDatetime);
      return date.toISOString().split('T')[0]; 
    },
    convertUtcToIst(utcDate) {
      const date = new Date(utcDate);
    
     
      const istOffset = 5.5 * 60; 
      const utcOffset = date.getTimezoneOffset(); 
      const istDate = new Date(date.getTime() + (istOffset + utcOffset) * 60000); 
      
      
      const hours = istDate.getHours().toString().padStart(2, '0');
      const minutes = istDate.getMinutes().toString().padStart(2, '0');
      
      return `${hours}:${minutes}`;
    },
    combineDateAndTime(date, time) {
      const dateTimeString = `${date}T${time}Z`;
      const dateObj = new Date(dateTimeString);
      return dateObj.toISOString();
    },
    clearReasonError() {
      this.reasonError = '';
    },
    closeModalAproveReject(){
      this.reason = '';
      this.reasonError = '';
      this.showAproveRejectPopup = false;
    }
    
    
    
  },
};
