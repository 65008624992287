<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-8">
      <h2 class="">Study Monitoring</h2>
    </div>
    <div class="col-lg-4">
      <div class="title-action">

      </div>
    </div>

  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
  
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <!-- <div class="ibox-title style_2">
             <small class="float-right text-muted">Informations</small>
              text
          </div> -->
          <div class="row" v-if="refreshFlags['DataAnalytics']">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/sitequerymonitoring">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge dm-color align-middle mr-1"
                            >SM</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center mt-1"
                        >
                          <h4 class="font-bold">Site Monitoring</h4>
                          <span>
                            <h4>
                              <small>Site monitoring - Query</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/aesaemonitoring">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1"
                            >AE</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center mt-1"
                        >
                          <h4 class="font-bold">AE/SAE Monitoring</h4>
                          <span>
                            <h4>
                              <small>AE/SAE Monitoring</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/siteactivitymonitoring">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge dm-color align-middle mr-1"
                            >SA</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center mt-1"
                        >
                          <h4 class="font-bold">Site Activity Monitoring</h4>
                          <span>
                            <h4>
                              <small>Site Activity monitoring - Visit Schedule</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                 </div>
            </div>
            <div class="col-md-3 dash-widget">
              <h4>{{ $t("navigations.insights portal") }}</h4>
              <div>
                <ul class="list-group">
                  <router-link to="/sitequerymonitoring">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side dm-color align-middle mr-1"
                        >SM</span
                      >
                      <span> Site Monitoring</span>                      
                    </li>
                 </router-link>
                  
                </ul>
                <ul class="list-group">
                  <router-link to="/aesaemonitoring">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >AE</span
                      >
                      <span> AE/SAE Monitoring</span>                      
                    </li>
                 </router-link>
                  
                </ul>
                <ul class="list-group">
                  <router-link to="/siteactivitymonitoring">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side dm-color align-middle mr-1"
                        >SA</span
                      >
                      <span> Site Activity Monitoring</span>                      
                    </li>
                 </router-link>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./Study_monitoring"></script>
<style scoped>
.activeTab {
  color: #495057;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff;
}
</style>