/* eslint-disable */
import axios from "axios";
import moment from 'moment';
import store from "../../store/index";
import jwt_decode from "jwt-decode";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import CreateVideoCallSchedulePopUp from "../../components/CreateVideoCallSchedulePopUp/CreateVideoCallSchedulePopUp.vue";
// import from 'jstree'
export default {
  components: {
    VPagination,
    CreateVideoCallSchedulePopUp
  },
  data() {
    return {
       FillForm: "FillForm",
      sProp: "",
      IsDesc: true,
      defaultdate: "",
      roleprivileges: "",
      baseapi: process.env.VUE_APP_Service_URL,
      localurl: process.env.VUE_APP_redirect,
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patientschedule/`,
      currentTemplatePage: 1,
      totalTemplatePages: 1,
      overduevisits: [],
      plannedvisits: [],
      sites: [],
      pageplannedindex: 1,
      pageplannedsize: 10,
      recentpageplannedsize: 10,
      currentplannedPage: 1,
      totalplannedCount: 0,
      totalrecentCount: 0,
      totalplannedPages: 0,
      pageoverdueindex: 1,
      pageoverduesize: 10,
      currentoverduePage: 1,
      totaloverdueCount: 0,
      totaloverduePages: 0,
      pageformindex: 1,
      pageformsize: 10,
      currentformPage: 1,
      totalformCount: 0,
      totalformPages: 0,
      patientLists: [],
      visitobj: {},
      formList: [],
      recentformList: [],
      currentRecentformPage: 1,
      totalformsPages: 0,
      recentpageformindex: 1,
      recentpageformsize: 10,
      SortProperty: "",
      SortOrder: 1,
      investigatorEmail: "",
      showSidePopup: [[[false]]],
      templateList: [],
      visitFormObj: {},
      selectedVisit: {},
      selectedTemplate: "",
      sohowFormModal: false,
      sohowFormModalImminent:false,
      formlist: [],
      CreateVideoCallSchedulePopUp:false,
      selectedPatientId: null,
    };
  },

  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.getoverduevisits();
      this.getplannedvisits();
      this.getsites();
      this.getForms();
      this.GetRecentFormData();
    });
    await this.setroleprivileges();
    this.investigatorEmail = jwt_decode(store.getters.getIdToken)
    this.getoverduevisits();
    this.getplannedvisits();
    this.getsites();
    this.getForms();
    this.GetRecentFormData();
  },
  methods: {
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    async openFormsModal(visitNo, patientId, studyId) {
      await this.listvisitForms(visitNo, patientId, studyId);
      this.sohowFormModal = true;
    },
    async openImminentFormsModal(visitNo, patientId, studyId) {
      await this.listvisitForms(visitNo, patientId, studyId);
      this.sohowFormModalImminent = true;
    },
    async closeFormsModal() {
      this.sohowFormModal = false;
    },
    async closeImminentFormsModal() {
      this.sohowFormModalImminent = false;
    },
    openVideoCallSchedulePopup(patientId) {
      this.selectedPatientId = patientId; // Set the selected patient ID
      console.log("Patient id is",this.selectedPatientId);
      this.CreateVideoCallSchedulePopUp = true; // Open the popup
    },
    // async viewForm(formId, isPatientVisit) {
    //   this.$router.push(`/editForm/submitForm/${formId}/${isPatientVisit}`);
    // },
    async listvisitForms(visitNo, patientId, studyId) {
      const idtoken = store.getters.getIdToken;
      await
        axios.get(`${this.baseUrl}getfromsbyvisit?studyId=${studyId}&patientID=${patientId}&visitNo=${visitNo}`, {          
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        )
        .then((res) => {
          console.log("Visit form list is...", res);
          this.formlist = res.data.data;
        })
          .catch(err => console.log(" error in getting visit forms...", err));
    },
    async gotoform(singlevisit, autosubjectid, autositecode) {
      if(singlevisit.visitStatus == 'Withdrawn'){
        alert("The form has already been withdrawn.");
      }
      else{
      console.log("singlevisit", singlevisit);
      await store.dispatch("setautofillsiteid", autositecode);
      await store.dispatch("setautofillsubjectid", autosubjectid);
      if (singlevisit.formId == null || singlevisit.formId == "") {
        let tempvar = {
          "Siteid": singlevisit.siteId,
          "Subject_id": singlevisit.patientId,
          "Visitname": singlevisit.visitName,
          "Visitno": singlevisit.visitNo,
          "sheduleId": singlevisit.sheduleId
        }
        await store.dispatch("commitvisitdetails", tempvar);
        console.log("IspatientVist?", singlevisit.isPatientVisit)
        if (singlevisit.templateId == null || singlevisit.templateId == "") {
          alert(this.$t("navigations.no template assigned"))
        }
        else {
          this.$router.push(`/form/${singlevisit.isPatientVisit}/${singlevisit.templateId}/${singlevisit.studyId}/0 `);
        }

        // this.$router.push(`/form/${visits.templateId}/${visits.studyId}/${visits.visitid}`);
      }

      else {
        this.$router.push(`/editForm/submitForm/${singlevisit.formId}/${singlevisit.isPatientVisit} `);
      }
    }
    },
    async gotofillform(visits, autosubjectid, autositecode) {
      console.log("gotofillform visits", visits);
      await store.dispatch("setautofillsiteid", autositecode);
      await store.dispatch("setautofillsubjectid", autosubjectid);
      const template = this.templateList.find(t => t.templateId === this.selectedTemplate);
      let tempvar = {
        "Siteid": visits.siteId,
        "Subject_id": visits.patientId,
        "Visitname": visits.visitName,
        "Visitno": visits.visitNo,
        "sheduleId": visits.sheduleId,
        "Template_name": template.templateName
      }
      await store.dispatch("commitvisitdetails", tempvar);
      await store.dispatch("setfrompatientschedule", 'frompatientschedule');
      this.$router.push({
        path: `/form/${visits.isPatientVisit}/${this.selectedTemplate}/${visits.studyId}/0`,
        query: {
          tempObj: encodeURIComponent(JSON.stringify(tempvar))
        }
      });

    },
    async goToForms() {
      if (visits.formId == null || visits.formId == "") {
        this.gotoform();
      }
      else {
        this.EditForm();
      }
    },
    async EditForm(formId, isPatientVisit) {
      this.$router.push(`/editForm/submitForm/${formId}/${isPatientVisit}`);
    },
    async goTovisitTemplate(lists,patientId) {
      if(lists[0].visitStatus == 'Withdrawn'){
        alert("The form has already been withdrawn.");
      }
      else{
      localStorage.setItem("patid",patientId)
      console.log("List visit is ", lists);
      console.log("TEMPLATE ID IS", await lists[0].templateId, await lists);
      await store.dispatch("setautofillsiteid", lists[0].siteCode);
      await store.dispatch("setautofillsubjectid", lists[0].patientNo);
      if (await lists[0].templateId != null && await lists[0].templateId != "null" && await lists[0].formId == null || await lists[0].formId == "null") {
        let tempvar = {
          "Siteid": lists[0].siteId,
          "Subject_id": lists[0].patientId,
          "Visitname": lists[0].visitName,
          "Visitno": lists[0].visitNo,
          "sheduleId": lists[0].sheduleId
        }

        await store.dispatch("commitvisitdetails", tempvar);
        this.$router.push(`/form/${lists[0].isPatientVisit}/${lists[0].templateId}/${lists[0].studyId}/0 `);
      }
      else if (await lists[0].templateId != null && await lists[0].templateId != "null" && await lists[0].formId != null && await lists[0].formId != "null") {
        this.$router.push(`/editForm/submitForm/${lists[0].formId}/${lists[0].isPatientVisit} `);
      }

      else if (await lists[0].templateId == null || await lists[0].templateId == "null") {
        alert(this.$t("navigations.no template assigned"))
      }
    }
    },
    async goToTemplate(isPatientVisit,templateid,visitid){
      const studyid = store.getters.getStudyIs;
      this.$router.push(`/form/${isPatientVisit}/${templateid}/${studyid}/${visitid}`);
    },
    async isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    async viewform(id) {
      this.$router.push(`/editForm/submitForm/${id}/0`);
    },
    async setFontSize(el) {
      var fontSize = el.val();

      if (isNumber(fontSize) && fontSize >= 0.5) {
        $('body').css({ fontSize: fontSize + 'em' });
      } else if (fontSize) {
        el.val('1');
        $('body').css({ fontSize: '1em' });
      }
    },
    async itemClick(node) {
      console.log(node.model.text + ' clicked !')
    },

    filter(data) {
      if (data) {
        return moment(data).utc().format(this.defaultdate);
      }
      console.log(data);
    },
    filterMonth(date) {
      if (date) {
        //console.log("dateeeeeeeeee",date)
        return moment(date).format('DD-MMM-YYYY')
      }
    },
    SortSelectforms(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortDesc")) {
            return
          }
          else if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else if (selectedElement.classList.contains("sortAsc")) {
            return
          }
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.GetRecentFormData();
    },
    async GetRecentFormData() {
      const idtoken = store.getters.getIdToken;
      const stdid = store.getters.getStudyIs;
      await axios
        .get(`${this.baseapi}/forms/forms/getpaginatedrecentforms?studyId=${stdid}&userEmail=${this.investigatorEmail.email}&pageindex=${this.recentpageformindex}&pagesize=${this.recentpageformsize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}&api-version=1.0`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        )
        .then((response) => {
          if (response.data) {
            console.log("study id iSssssssssss", stdid)
            this.recentformList = response.data.results
            console.log("Recent form table data", response.data);
            this.totalrecentCount = response.data.totalCount;
            // if(response.data.totalCount>30)
            // {
            //   this.totalrecentCount=30
            // } 
            this.totalformsPages = parseInt(this.totalrecentCount / this.recentpageplannedsize);
            if (this.totalrecentCount % this.recentpageplannedsize != 0) {
              console.log("In iffffffffff", this.totalrecentCount % this.recentpageplannedsize)
              this.totalformsPages = this.totalformsPages + 1;
            }


            console.log("PAGES VALUEEEE", this.totalformsPages, this.totalrecentCount)
          }
          else {
            console.log("no forms found");
          }
        })

        .catch((err) => {
          console.log(err);
        });
    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        if (this.IsDesc == true) {
          if (selectedElement.classList.contains("sortDesc")) {
            return
          }
          else if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else if (selectedElement.classList.contains("sortAsc")) {
            return
          }
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.sProp = sortProp;
      this.IsDesc = this.IsDesc == true ? false : true;
      this.getoverduevisits();
    },
    async getoverduevisits() {
      this.overduevisits = []
      const currentstudyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/patientschedule/getshedules?Investigator=${this.investigatorEmail.name}&StudyId=${currentstudyid}&VisitStatus=Overdue&PageNo=${this.pageoverdueindex}&ItemCount=${this.pageoverduesize}&sortprop=${this.sProp}&isDes=${this.IsDesc}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.overduevisits = res.data.data;
          console.log("Overdue visits are", this.overduevisits)
          this.totaloverdueCount = res.data.totalCount;
          this.totaloverduePages = parseInt(res.data.totalCount / this.pageoverduesize);
          if (this.totaloverdueCount % this.pageoverduesize != 0) {
            this.totaloverduePages = this.totaloverduePages + 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SortSelect(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        if (this.IsDesc == true) {
          if (selectedElement.classList.contains("sortDesc")) {
            return
          }
          else if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else if (selectedElement.classList.contains("sortAsc")) {
            return
          }
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.sProp = sortProp;
      this.IsDesc = this.IsDesc == true ? false : true;
      this.getplannedvisits();
    },
    async getplannedvisits() {
      this.plannedvisits = []
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;

      await axios
        .get(`${this.baseapi}/management/patientschedule/getshedules?Investigator=${this.investigatorEmail.name}&StudyId=${currentstudy}&VisitStatus=Imminent&PageNo=${this.pageplannedindex}&ItemCount=${this.pageplannedsize}&sortprop=${this.sProp}&isDes=${this.IsDesc}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.plannedvisits = res.data.data;
          console.log("Imminent visits are", this.plannedvisits)
          this.totalplannedCount = res.data.totalCount;
          this.totalplannedPages = parseInt(res.data.totalCount / this.pageplannedsize);
          if (this.totalplannedCount % this.pageplannedsize != 0) {
            this.totalplannedPages = this.totalplannedPages + 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getsites() {
      const currentstudyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sites = res.data;
          console.log("Sites are", this.sites)
          console.log("Current study is", currentstudyid)
          console.log(idtoken)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getpatient(siteId, index) {
      this.patientLists[index] = [];
      console.log("Site id is", siteId)
      const idtoken = store.getters.getIdToken;
      const currentstudyid = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/patient/listpatient?SiteId=${siteId}&Studyid=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          let data = res.data.results;
          await data.forEach(patientdata => {
            this.visitobj[patientdata.patientId] = [];
          });
          this.patientLists[index] = data;
          console.log("patient list is", this.visitobj)
        });
      // await this.setvisitobj(this.patientLists[index]);
    },
    async setvisitobj(data) {
      console.log("set visit function");
      console.log("patient list is", data);
      data.forEach(patientdata => {
        this.visitobj[patientdata.patientId] = [];
      });
      console.log(this.visitobj);
    },
    async getVisits(patientId, index) {
      console.log("get visit function");
      this.visitobj[patientId][index] = [];
      const idtoken = store.getters.getIdToken;
      console.log("patient ID is", patientId)

      await axios
        .get(
          `${this.baseapi}/management/patientschedule/getpatientshedule?patientID=${patientId}&ItemCount=500`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.visitobj[patientId][index] = res.data.data;
          console.log("The visit list is", this.visitobj)
        })
        .catch((err) => {
          console.log("error is", err);
        })
      this.displayTable = true
    },
    // async getForms()
    // { 
    //   const idtoken = store.getters.getIdToken;
    //   const currentstudyid = store.getters.getStudyIs;
    //   await axios
    //   .get(
    //     `${this.baseapi}/management/visit/listvisit?StudyId=${currentstudyid}&pageindex=${this.pageformindex}&pagesize=${this.pageformsize}`,
    //     {
    //       headers: {
    //         Authorization: "Bearer " + idtoken,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //       this.formList = res.data.results;
    //       console.log("The form list is",this.formList)
    //       this.totalformCount = res.data.totalCount;
    //       this.totalformPages=parseInt(this.totalformCount / this.pageformsize)
    //        if((this.totalformCount % this.pageformsize) !=0){
    //         this.totalformPages = this.totalformPages+1
    //       }
    //   })
    //   .catch((err) =>{
    //       console.log("error is" , err);
    //   })
    //   this.displayTable=true
    // },
    loadTemplate: function (page) {
      this.currentTemplatePage = page;
      this.getForms();
    },
    async getForms() {
      const currentstudyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
     const head = {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      }
      axios.get(`${this.baseapi}/forms/template/listtemplate?StudyID=${currentstudyid}&Status=Approved&pageindex=${this.currentTemplatePage}&pagesize=5&SortProperty=templateName&SortOrder=0`, head)
        .then((res) => {
          console.log("template details ", res.data);
          this.formList = res.data.results;

          let totalCount = res.data.totalCount;
          this.totalTemplatePages = parseInt(totalCount / 5);
          if (totalCount % 5 != 0) {
            this.totalTemplatePages = this.totalTemplatePages + 1;
          }
        })
        .catch(err => console.log("error in get study", err));
    },
    async listTemplate(){
      const idtoken = store.getters.getIdToken;
      const currentstudyid = store.getters.getStudyIs;
      await axios.get(`${this.baseapi}/forms/template/listtemplate?Status=Approved&StudyID=${currentstudyid}&&SortProperty=templateName&SortOrder=0 `,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        console.log("Study template list is...", res.data);
        this.templateList = res.data.results;
      })
        .catch(err => console.log("error while getting template list", err))
    },
    toggleSidePopup(sIndex, pIndex, vIndex) {
      console.log("Toggle", sIndex, this.showSidePopup);

      // Initialize arrays if they don't exist
      if (!this.showSidePopup[sIndex]) {
        this.showSidePopup[sIndex] = [];
      }
      if (!this.showSidePopup[sIndex][pIndex]) {
        this.showSidePopup[sIndex][pIndex] = [];
      }

      // Toggle the value
      if (typeof this.showSidePopup[sIndex][pIndex][vIndex] === 'undefined') {
        this.showSidePopup[sIndex][pIndex][vIndex] = false;
      }
      this.showSidePopup[sIndex][pIndex][vIndex] = !this.showSidePopup[sIndex][pIndex][vIndex];
    }
    ,
    closeSidePopup(sIndex, pIndex, vIndex) {
      if (this.showSidePopup[sIndex] && this.showSidePopup[sIndex][pIndex]) {
        this.showSidePopup[sIndex][pIndex][vIndex] = false;
      }
    },
    async openModal(visit, subjectId, siteCode, visitstatus) {
      if(visit.withdrawnDate){
        alert("You cannot add new forms to a withdrawn visit");
      }
      else{
      console.log("Open Modal", visit, subjectId, siteCode)
      this.selectedVisit.visit = visit;
      this.selectedVisit.subjectId = subjectId;
      this.selectedVisit.siteCode = siteCode;
      console.log("selected visit is ", this.selectedVisit);
      // this.selectedmodeltemplate = "";
      // this.selectedvisitid = visitid;
      await this.listTemplate();
      const modalWrapper = document.querySelector(".modal-wrapper");
      modalWrapper.classList.add("active");
      }
    },
    
    closeModal() {
      // this.selectedmodeltemplate = "";
      const modalWrapper = document.querySelector(".modal-wrapper");
      modalWrapper.classList.remove("active");
    },
    async getFormsByVisit(subjectId, visitNo, visitName) {
      this.visitFormObj[visitNo + subjectId] = [];
      const idtoken = store.getters.getIdToken;
      const stdid = store.getters.getStudyIs;
      await axios.get(`${this.baseapi}/management/patientschedule/getfromsbyvisit?studyId=${stdid}&patientID=${subjectId}&visitNo=${visitNo}&visitName=${visitName}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        this.visitFormObj[visitNo + subjectId] = res.data;
        console.log("getfromsbyvisit list is...", this.visitFormObj, visitNo);
        console.log("data is ", this.visitFormObj[visitNo + subjectId].data)
      })
        .catch(err => console.log("error while getting visit forms list", err)

        )
    },
    async listVisitForms(siteId, subjectId, visitNo) {
      console.clear();
      this.visitFormObj[visitNo] = [];
      const idtoken = store.getters.getIdToken;
      const stdid = store.getters.getStudyIs;
      await axios.get(`${this.baseapi}/forms/forms/getformsbyids?studyid=${stdid}&siteid=${siteId}&subjectid=${subjectId}&visitno=${visitNo}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        this.visitFormObj[visitNo] = res.data;
        console.log("Visit form list is...", this.visitFormObj);
        console.log("data is ", this.visitFormObj[visitNo])
      })
        .catch(err => console.log("error while getting visit forms list", err)

        )
    },
    loadPage: function (page) {
      this.currentoverduePage = page;
      this.pageoverdueindex = this.currentoverduePage;
      this.getoverduevisits();
    },
    loadPlanned: function (page) {
      this.currentplannedPage = page;
      this.pageplannedindex = this.currentplannedPage;
      this.getplannedvisits();
    },
    loadForm: function (page) {
      this.currentformPage = page;
      this.pageformindex = this.currentformPage;
      this.getForms();
    },
    loadrecentForm: function (page) {
      this.currentRecentformPage = page;
      this.recentpageformindex = this.currentRecentformPage;
      this.GetRecentFormData();
    },
  },
};
