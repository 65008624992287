<template>
  <div ref="component" class="stat-card-white">
    <div class="stat-header-white">
      <span>{{ chartLabel }}</span>
      <div v-if="!loader" class="d-flex align-items-center">
        <div class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img
            class="cursor-pointer"
            @click="showtable()"
            src="../../assets/img/table_view.png"
            alt="More block"
          />
          <div class="more-expand">
            <span>{{ $t("navigations.data overview") }}</span>
          </div>
        </div>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
          <img src="../../assets/img/expand.png" alt="Expand block" />
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="exporttoexcell()">Export to Excel</div>
            <div @click.prevent="exporttopdf()">Export to PDF</div>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-body-white overflow-auto">
      <div ref="chartContainerWrapper" class="w-100">
        <div v-if="!loader && selectedGroups.length!=0" class="d-flex flex-row-reverse gap-2 ">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
        <svg ref="chartContainer" v-show="!loader"></svg>
      </div>
      <div ref="tooltip" class="tooltip"></div>
    </div>
    <div v-if="colorcodes.length!=0" class="d-flex">
      <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
        <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
        {{ colors.type }}
      </div>
    </div>
    <enlargerpopup v-if="expandBlock" @closeModal="expandBlock = false; createChart()">
      <div class="stat-body-white d-flex flex-column">
        <svg ref="popupChartContainer"></svg>
      </div>
    </enlargerpopup>
  </div>
</template>

<script>
import * as d3 from "d3";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";

export default {
  name: "comparativeBarChart",
  components: {
    enlargerpopup,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    xAxisValue: {
      type: String,
      default: "",
    },
    yAxisValue: {
      type: String,
      default: "",
    },
    customisationfields: {
      type: Array,
      default: () => [],
    },
    chartLabel: {
      type: String,
      default: "Comparative Analysis",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 1000,
    },
    height: {
      type: Number,
      default: 400,
    },
    custcolors: {
      type: Array,
      default: () => ["#FFA500", "#D32F2F", "#ABF0FF", "#C1D1FF", "#FBD164", "#FDAD73"],
    },
    colorcodes: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    sort: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      expandBlock: false,
      tooltip: null,
      selectedGroups: [],
      showVisitPopup: false,
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      chartContainer:null,
      mouseX: 0,
      mouseY: 0,
    };
  },
  mounted() {
    this.tooltip = this.$refs.tooltip;
    this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
    if (this.data && this.customisationfields.length) {
      this.createChart();
    }
    window.addEventListener("mousemove", this.updateMousePosition);
  },
  watch: {
    data: {
      handler() {
        console.log("predata",this.data);
        if (this.data && this.customisationfields.length) {
          console.log("data",this.data);
          this.createChart();
          this.cancelselection();
        }
      },
      deep: true,
    },
    customisationfields: {
      handler() {
        if (this.data && this.customisationfields.length) {
          this.createChart();
        }
      },
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("mousemove", this.updateMousePosition);
  },
  methods: {
    recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
            const groupandxaxis={
      "xaxiskey":this.selectedXAxis,
      "groupkey":this.selectedGroupCategory,
      }
      this.$emit("setgroupkeys", groupandxaxis);
    },
    createChart() {
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
      const margin = { top: 20, right: 30, bottom: 70, left: 80 };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      const aggregatedData = this.aggregateData();

      const x0 = d3
        .scaleBand()
        .domain(aggregatedData.map((d) => d.key))
        .range([0, width])
        .padding(0.2);

      const x1 = d3
        .scaleBand()
        .domain(this.customisationfields.map((field) => field.name))
        .range([0, x0.bandwidth()])
        .padding(0.05);

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(aggregatedData, (d) => d3.max(d.values, (v) => v.value))])
        .nice()
        .range([height, 0]);

      const colorScale = d3
        .scaleOrdinal()
        .domain(this.customisationfields.map((field) => field.name))
        .range(this.custcolors);

      d3.select(this.chartContainer).selectAll("*").remove();

      const svg = d3
        .select(this.chartContainer)
        .attr("width", this.width)
        .attr("height", this.height)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      svg
        .append("g")
        .selectAll("g")
        .data(aggregatedData)
        .enter()
        .append("g")
        .attr("transform", (d) => `translate(${x0(d.key)},0)`)
        .selectAll("rect")
        .data((d) => d.values)
        .enter()
        .append("rect")
        .attr("x", (d) => x1(d.category))
        .attr("y", (d) => y(d.value))
        .attr("height", (d) => height - y(d.value))
        .attr("width", x1.bandwidth())
        .attr("fill", (d) => colorScale(d.category))
        .attr("style", (d) =>
          this.selectedGroups.length!=0?(this.selectedGroups.includes(d.key) ? "" : "opacity: 35%;"):""
        )
        .on('click', (event, d) => {
      d3.select(this.tooltip).style("opacity", 0);
      console.log("data is",d);
      this.drilldowndata(d.records,d.key);
      
    })
        .on("mouseover", (event, d) => {
          d3.select(this.tooltip)
            .style("opacity", 1)
            .html(`<strong>${d.category}</strong>: ${d.value}`)
            .style("left", `${this.mouseX + 10}px`)
            .style("top", `${this.mouseY + 10}px`);
        })
        .on("mousemove", () => {
          d3.select(this.tooltip)
            .style("left", `${this.mouseX + 10}px`)
            .style("top", `${this.mouseY + 10}px`);
        })
        .on("mouseout", () => {
          d3.select(this.tooltip).style("opacity", 0);
        });

      svg
        .append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x0))
        .selectAll("text")
        .attr("transform", "rotate(-45)")
        .style("text-anchor", "end");

      svg.append("g").call(d3.axisLeft(y));

      // X-Axis Label
      svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom - 10)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .text(this.xAxisValue);

      // Y-Axis Label
      svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("x", -(height / 2))
        .attr("y", -margin.left + 20)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .text("Count");
    },
    drilldowndata(filteredData, filteredkeyword) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `(${this.selectedGroups})`;
      } else {
    this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = `(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createChart();
  },
  async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.createChart();
},
  backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    aggregateData() {
      const groupedData = d3.group(this.data, (d) => d[this.xAxisValue]);
      return Array.from(groupedData, ([key, records]) => {
        console.log("records",records)
        const values = this.customisationfields.map((field) => {
          if (field.value === "count") {
            return {
              category: field.name,
              value: records.length,
              color: field.color || "gray",
              records:records,
              key:key+" "+field.name
            };
          } 
          if (field.value === "number") {
            return {
              category: field.name,
              value: records[0][field.fieldname],
              color: field.color || "gray",
              records:records,
              key:key+" "+field.fieldname
            };
          }else {
            const count = records.filter((r) => r[field.fieldname] === field.value).length;
            const saerec = records.filter((r) => r[field.fieldname] === field.value);
            return {
              category: field.name,
              value: count,
              color: field.color || "gray",
              records:saerec,
              key:key+" "+field.name
            };
          }
        });
        return { key, values };
      });
    // }
    },
    showtable() {
      if (this.fromPage == "studySummary") {
        const uniqueData = this.data.reduce((acc, current) => {
          const uniqueKey = `${current.QUERYID}_${current.STUDYREF}`;
          if (
            current.QUERYID !== null &&
            !acc.some((item) => `${item.QUERYID}_${item.STUDYREF}` === uniqueKey)
          ) {
            acc.push(current);
          }
          return acc;
        }, []);
        this.$emit(
          "opendrillpopup",
          uniqueData.map((item) => {
            const newItem = { ...item };
            delete newItem.STUDYID;
            return newItem;
          }),
          "query"
        );
      } else {
        this.$emit("opendrillpopup", this.data);
      }
    },
    async exporttoexcell() {
      if (this.fromPage == "studySummary") {
        const filteredData = this.data.map((item) => {
          const newItem = { ...item };
          delete newItem.FORMID;
          return newItem;
        });
        const uniqueData = filteredData.reduce((acc, current) => {
          const uniqueKey = `${current.QUERYID}_${current.STUDYREF}`;
          if (
            current.QUERYID !== null &&
            !acc.some((item) => `${item.QUERYID}_${item.STUDYREF}` === uniqueKey)
          ) {
            acc.push(current);
          }
          return acc;
        }, []);
        const worksheet = XLSX.utils.json_to_sheet(uniqueData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Query Count.xlsx");
      } else {
        this.$emit("exporttoexcell");
      }
    },
    exporttopdf() {
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = "none";
      const pdfContainer = document.createElement("div");

      const captureArea = this.$refs.component.cloneNode(true);
      pdfContainer.appendChild(captureArea);

      pdfContainer.style.width = "100%";

      pdfContainer.style.display = "block";
      const opt = {
        margin: 0,
        filename: `${
          this.fromPage == "studySummary"
            ? `${this.$t("navigations.query count")}.pdf`
            : `Comparative Analysis.pdf`
        }`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "pt", format: "a4", orientation: "landscape" },
      };

      html2pdf().set(opt).from(pdfContainer).save();
    },
    updateMousePosition(event) {
      if (this.$refs.component) {
        const rect = this.$refs.component.getBoundingClientRect();
        this.mouseX = event.clientX - rect.left;
        this.mouseY = event.clientY - rect.top;
      }
    },
  },
};
</script>

<style scoped>
.legandbox {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
.stat-card-white {
  background-color: #fff;
  padding: 1em;
  margin: 1em 0;
}
.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 1000;
}
</style>
